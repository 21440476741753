
import { Component, Prop, Emit, Ref } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DetectClickOutside, { ClickOutsideEvent } from '@/mixins/DetectClickOutside';
import { KeyCodes } from '@/services/helpers/constants';
import TagsModule from '@/store/modules/tags/TagsModule';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import { Tag, TagsGroup } from '@/typings/domain';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
    name: 'Editor',
    components: { Checkbox },
})
export default class Editor extends mixins(DetectClickOutside) {
    innerValue: Tag | TagsGroup;
    eventEmited = false;
    @Prop() value: Tag | TagsGroup;
    @Prop() header: string;
    @Prop( {default: 30 }) valueLength: number;
    @Prop({ default: null }) contentType: string;
    @Ref() readonly inputElement!: HTMLElement;

    private _uid: number;
    public get tags() {
        return TagsModule.tags;
    }
    public error = false;
    checkboxValue(id: number) {
        return (this.innerValue as TagsGroup).tags.some(el => el.id === id);
    }

    editTagList(tag: Tag) {
        const tagList = this.innerValue as TagsGroup;
        if (tagList.tags.some((el: Tag) => el.id === tag.id)) {
            tagList.tags = tagList.tags.filter((el: Tag) => el.id !== tag.id);
            this.innerValue = tagList;
        } else {
            tagList.tags.push(tag);
            this.innerValue = tagList;
        }
    }

    onKeyDown(e: KeyboardEvent) {
        if (e.key === KeyCodes.Escape) this.onBlur(e);
        if (e.key === KeyCodes.Enter) this.preSubmit(e);
    }

    preventDubbleEmit() {
        if (!this.eventEmited) {
            this.eventEmited = true;
            return false;
        } else {
            return true;
        }
    }

    blockScroll(value: boolean) {
        const id = this._uid.toString();
        if(value)            
            GeneralModule.addScrollBlocker(id);
        else 
            GeneralModule.removeScrollBlocker(id);
    }
    preSubmit(e: Event)
    {
        if(this.innerValue.name.length)
        {
            if(this.valueLength)
            {   
                if(this.innerValue.name.length > this.valueLength)
                {
                    this.error = true;
                    Notify.error('Максимальная количество символов 30')
                    return;
                }
                else
                {
                    this.error = false;
                    this.onSubmit(e)
                }
            }
        }
        else
        {
            Notify.error('Поле не может быть пустым!')
            this.error = true;
        }
    }
    // Emitters

    @Emit('submit')
    onSubmit(e: Event) {      
        if (this.preventDubbleEmit()) return;
        e.stopPropagation();

        if (this.innerValue.name.length) {
            return this.innerValue;
        } else {
            Notify.error(i18n.t('Errors.NoEmptyFields').toString());
        }
    }

    @Emit('cancel')
    onBlur(e: Event) {
        if (this.preventDubbleEmit()) return;
        e.stopPropagation();
        return {...this.value};
    }

    // Hooks

    beforeMount() {
        this.innerValue = Object.assign({}, this.value);
    }

    mounted() {
        this.inputElement.focus();
        this.$on(ClickOutsideEvent, this.onBlur);
        this.blockScroll(true);
    }

    beforeDestroy() {
        this.$off(ClickOutsideEvent, this.onBlur);
        this.blockScroll(false);
    }
}
