
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/ui/dropdown';

@Component({
  name: 'DropdownTitle',
  components: {
    Dropdown,
  },
})
export default class DropdownTitle extends Vue {
  @Prop({default: false}) left: boolean
  @Prop({default: 0}) specialisations: number
  isExpanded = false;
  
  public change = (value: boolean) => {
    this.isExpanded = value;

    this.onToggle(value);
  };

  @Emit('toggle')
  onToggle(value: boolean)
  {
    return value;
  }
}
