
import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';

import * as CSS from 'csstype';
import { TabItem } from './model';
import throttle from '@/utils/throttle';

import Tab from './Tab.vue';
import { SubRole, Tag as KnowledgeTag } from '@/typings/domain';

@Component({
  components: {
    Tab,
  },
})
export default class Tabs extends Vue {
  onResize: Function;

  @Ref('wrapper') readonly wrapper!: HTMLElement;

  @Prop() tabs: Array<TabItem>;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) wide: boolean;
  @Prop({ default: false }) attached: boolean;
  @Prop() selected: Array<string | SubRole>;

  @Prop() dataTabs: Array<SubRole>;

  public innerSelected: Array<string | SubRole> = [];
  public style: CSS.Properties = {};
  public selectedDataTab: Array<SubRole>;

  public isSelected(value: string) {
    return this.innerSelected.includes(value);
  }

  public getType(value: string): string {
    if (this.isSelected(value)) return 'plain';
    return this.attached ? 'link' : 'outline';
  }

  public getColor(value: string): string {
    const isSelected = this.isSelected(value);
    if (isSelected && !this.attached) return 'light-100';
    if (isSelected && this.attached) return 'dark-700';

    return 'dim';
  }

  public generateStyles() {
    if (!this.wrapper) return;

    const rect = this.wrapper.getBoundingClientRect();
    const left = rect.left;
    const right = window.innerWidth - rect.left - rect.width;

    this.style = {
      paddingLeft: `${left}px`,
      paddingRight: `${right}px`,
    };
  }

  public select(value: string, tab?: SubRole | KnowledgeTag, event?: Event) {
    if (this.multiple) {
      const index = this.innerSelected.indexOf(value);
      if (index === -1) {
        this.innerSelected.push(value);
      } else {
        this.innerSelected.splice(index, 1);
      }
    } else {
      if (tab) {
        this.innerSelected = [`${tab['id']}`];
        this.selectedDataTab = [tab];
      } else {
        this.innerSelected = [value];
      }
    }
    this.scrollToSelected(event)
  }

  scrollToSelected(e: Event) {
    const element = e.target as HTMLElement
    
    if (element) {
      element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }

  @Watch('innerSelected')
  @Emit('change')
  onChange() {
    if (this.selectedDataTab) {
      return this.selectedDataTab
    } else {
      return this.innerSelected;
    }
  }

  @Watch('selected')
  setSelected(): void {
    if (!this.selected) return;
    this.innerSelected = this.selected;
  }

  created() {
    this.setSelected();
  }

  mounted() {
    if (this.wide) {
      this.generateStyles();
      this.onResize = throttle(this.generateStyles, 150);
      window.addEventListener('resize', this.generateStyles);
    }
  }

  beforeUnmount() {
    if (this.wide) {
      window.removeEventListener('resize', this.generateStyles);
    }
  }
}
