
import { Component, Vue, Ref } from 'vue-property-decorator';

import { NavSection } from '@/modules/settings/model';
import ROUTES from '@/router/ROUTES';

import SETTINGS_ROUTES from '@/modules/settings/router/SETTINGS_ROUTES';
import Divider from '@/modules/settings/components/divider/Divider.vue';
import SidenavButton from '@/modules/settings/components/sidenav-button/SidenavButton.vue';

import PopupContext from '@/modules/settings/views/directions/components/Editor.vue';
import Button from '@/components/ui/button';
import DropdownTitle from '@/components/ui/dropdown-title';
import Tabs, { TabItem } from '@/components/ui/tabs';
import { ItemState } from '@/components/context-menu';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuToggle from '@/components/context-menu/items/ContextMenuToggle.vue';
import Dropdown from '@/components/ui/dropdown';
import RolesModule from '@/store/modules/roles/RolesModule';
import { NewRole, Role } from '@/typings/domain';
import SettingsModule from '@/store/modules/settings/SettingsModule';
import Notify from '@/services/helpers/notify';

const emptyNewRole = {
    name: '',
    isAdmin: false,
    isMinor: false,
};

@Component({
    name: 'Settings',
    components: {
        SidenavButton,
        Divider,
        DropdownTitle,
        Dropdown,
        PopupContext,
        Tabs,
        Button,
        ContextMenuSpawner,
        ContextMenuHeader,
        ContextMenuToggle,
    },
})
export default class Settings extends Vue {
    $isDesktop: boolean;

    public rolesPath = ROUTES.SETTINGS + SETTINGS_ROUTES.ROLES_AND_RIGHTS;

    private newRole: any = Object.assign({}, emptyNewRole);

    public popupStatus = false;
    public rolesPopupContextLabel = '';
    public rolesPopupContextMode = '';
    public get subsections(): Array<Array<NavSection>> {
        return [
            [
                {
                    title: this.$t('Settings.Title') as string,
                    path: ROUTES.SETTINGS + SETTINGS_ROUTES.MAIN,
                },
            ],
            [
                {
                    title: this.$t('Settings.Roles.Title') as string,
                    path: ROUTES.SETTINGS + SETTINGS_ROUTES.ROLES_AND_RIGHTS,
                },
                {
                    title: this.$t('Settings.Directions.Title') as string,
                    path: ROUTES.SETTINGS + SETTINGS_ROUTES.DIRECTIONS,
                },
            ],
            [
                {
                    title: this.$t('Settings.Knowledge.Title') as string,
                    path: ROUTES.SETTINGS + SETTINGS_ROUTES.KNOWLEDGE,
                },
                {
                    title: this.$t('Settings.Projects.Title') as string,
                    path: ROUTES.SETTINGS + SETTINGS_ROUTES.PROJECTS,
                },
        {
          title: this.$t('Settings.Teams.Title') as string,
          path: ROUTES.SETTINGS + SETTINGS_ROUTES.TEAMS,
        },
            ],
            ...(this.$isDesktop
                ? [
                      [
                          {
                              title: this.$t('Settings.DeleteAccount') as string,
                              state: ItemState.danger,
                          },
                      ],
                  ]
                : []),
        ];
    }

    @Ref('addRoleMenu') readonly spawner!: ContextMenuSpawner;

    updateRolesPopup(role: {name: string, roleId?: number})
    {
        this.popupStatus = false;
        if(this.rolesPopupContextMode === 'create')
        {
            console.log('333333')
            RolesModule.clearRoles();
            RolesModule.createRole({name: role.name, isAdmin: false}).then((data) => 
            {
                console.log('333334443')
                RolesModule.fetchRoles();
                Notify.success('Роль создана')
            })
            
            if(role.roleId)
            {
                RolesModule.editRole({name: role.name, is_admin: false, id: role.roleId})
            }
        }
    }
    
    closePopup()
    {
        this.popupStatus = false;
    }
    showPopupContext(mode: string)
    {
        if(mode === 'create')
        {
            this.rolesPopupContextMode = 'create';
            this.rolesPopupContextLabel = 'Добавить роль';
        }
        if(mode === 'edit')
        {
            this.rolesPopupContextMode = 'edit';
            this.rolesPopupContextLabel = 'Редактировать роль';
        }

        this.popupStatus = true;
    }

    // createRole()
    // {
        
    // }
    mounted()
    {
        SettingsModule.fetchSettings();
    }
    public get currentSubsectionTitle(): string {
        const subsectionsPlain: Array<NavSection> = [];
        this.subsections.forEach(group => {
            group.forEach(section => subsectionsPlain.push(section));
        });
        return subsectionsPlain.find(section => this.routeMatched(section.path)).title;
    }
    public get subsectionsAsTabs() {
        const items: Array<TabItem> = [];

        this.subsections.forEach(group => {
            group.forEach(section => {
                items.push({
                    title: section.title,
                    value: section.path,
                });
            });
        });

        return items;
    }
    beforeDestroy()
    {
        if(!RolesModule.roles.length)
        {   
            RolesModule.fetchRoles();
        }
    }
    public onTabsChange(value: Array<string>) {
        if (this.$route.path !== value[0]) {
            this.$router.push(value[0]);
        }
    }

    public get selectedTabs(): Array<string> {
        const selected = this.subsectionsAsTabs.find(tab => this.routeMatched(tab.value));
        return [selected.value];
    }

    routeMatched(route: string): boolean {
        return this.$route.matched.some(matchedRoute => matchedRoute.path === route);
    }
    // public async createRole() {
    //     RolesModule.createRole(this.newRole);

    //     this.newRole = {
    //         ...this.newRole,
    //         name: '',
    //     };

    //     if (this.$isDesktop) {
    //         this.spawner.close();
    //     }
    // }

    changeMinorState(value: boolean) {
        this.changeNewRoleFlag('isMinor', value);
    }

    changeAdminState(value: boolean) {
        this.changeNewRoleFlag('isAdmin', value);
    }

    changeNewRoleFlag(prop: 'isMinor' | 'isAdmin', state: boolean) {
        this.newRole[prop] = state;
    }
}
